@import './styles/animations';

.page {
  padding: 0 20px;
  &:nth-of-type(odd) {
    background-color: #fafafa;
  }

  @media (min-width: 768px) {
    padding: 100px 0;
  }
}
.home,
.about,
.psychodynamic,
.services,
.contact {
  opacity: 0.2;
  &.in-view {
    animation: fadeIn 100ms ease-in;
    opacity: 1;
  }
}

p,
span {
  font-size: 1.25rem;
  @media (max-width: 768px) {
    font-size: 1.1875rem;
  }
}
