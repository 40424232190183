@import '../styles/fonts';
.main-nav {
  position: relative;
  max-width: 100%;
  width: 680px;
  // margin: 0 auto;

  // @media (min-width: 769px) {
  //   margin: 40px auto;
  // }

  .main-nav-menu {
    display: flex;
    justify-content: space-between;
    list-style-type: none;

    // color: #867666;
    font-family: 'Questrial', sans-serif;
    font-size: 1.125rem;
    padding: 0;
    li {
      a {
        text-decoration: none;
        color: #000;

        &.active {
          padding-bottom: 5px;
          border-bottom: 1px solid #000;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  ~ .hamburger {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 999;
    width: 40px;
    height: 40px;

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
  @media (min-width: 769px) {
    flex-direction: row;
    ~ .hamburger {
      display: none;
    }
  }

  @media (max-width: 768px) {
    display: none;
    &.open {
      display: block;
      .main-nav-menu {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #e6e9e6;
        z-index: 99;
        margin: 0;
        justify-content: center;
        align-items: center;
        padding: 0;
        li {
          margin: 10px 0;
          a {
            font-size: 1.5rem;
          }
        }
      }
      // ~ .hamburger {
      //   position: fixed;
      //   top: 10px;
      //   left: 10px;
      //   z-index: 999;
      // }
    }
  }
}

.logo.small-screens {
  font-family: 'Caudex', serif;
  font-size: 24px;
  position: fixed;
  top: 10px;
  left: 58px;
  z-index: 99;

  @media (min-width: 769px) {
    display: none;
  }
}
.logo-sub.small-screens {
  font-size: 16px;
  position: fixed;
  top: 33px;
  left: 60px;
  z-index: 99;

  @media (min-width: 769px) {
    display: none;
  }
}
