@import '../styles/fonts';
.main-header {
  height: 60px;
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999999;
  .container {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 60px;
  }

  @media (min-width: 768px) {
    background-color: #afafaf;
  }
}
.logo {
  font-family: 'Caudex', serif;
  font-size: 20px;
  position: relative;
  padding-right: 10px;
  // top: 10px;
  // left: 58px;
  // z-index: 99;
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
}
.logo-sub {
  font-size: 14px;
  position: relative;
  padding-right: 10px;
  display: block;
  // top: 33px;
  // left: 60px;
  // z-index: 99;

  @media (max-width: 768px) {
    display: none;
  }
}
