@import '../../styles/fonts';

.therapy-details {
  margin-top: 40px;
  @media (max-width: 840px) {
    padding: 0 20px;
  }
  @media (min-width: 768px) {
    margin-top: 80px;
  }
  .heading {
    text-align: center;
    font-size: 1.5rem;
    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  .therapy-details-content {
    display: grid;
    width: 100%;
    max-width: 800px;
    margin: 40px auto;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 520px) {
      // flex-direction: column;
      grid-template-rows: 1fr auto;
      grid-template-columns: 100%;
    }
    img {
      border-radius: 10px;
      width: 100%;
      max-width: 100%;
      @media (max-width: 520px) {
        grid-row-start: 2;
        max-height: 200px;
        object-fit: cover;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      padding: 0 30px;
      @media (max-width: 520px) {
        grid-row-start: 1;
      }

      svg {
        width: 100px;
        margin: 0 auto;
        height: 80px;
      }
    }
  }
}
