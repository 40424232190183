@import '../../styles/fonts';

.hero {
  position: relative;
  width: 980px;
  max-width: 100%;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  position: relative;
  background-color: #e6e9e6;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0px 20px 20px 20px;

  @media (min-width: 580px) {
    margin-top: 20px;
    flex-direction: row;
    height: 403px;
    padding: 0;
  }
  img {
    max-width: 100%;
    object-fit: contain;
    object-position: right;
  }

  .hero-content {
    // position: absolute;
    display: flex;
    flex-direction: column;
    // align-content: center;
    // text-align: center;
    justify-content: center;
    padding: 20px 0;
    @media (min-width: 580px) {
      margin-top: 60px;
      flex-direction: row;
      height: 403px;
      padding: 20px;
    }
    @media (min-width: 768px) {
      padding: 20px 20px 20px 60px;
    }
  }

  .text {
    .main-text {
      font-size: 3rem;
      font-family: 'Caudex', serif;
      letter-spacing: 0.02em;
      @media (max-width: 768px) {
        font-size: 3rem;
      }
      @media (max-width: 580px) {
        font-size: 2rem;
      }
    }
    .sub-text {
      font-size: 1.5rem;
      line-height: 1.5rem;
      // text-align: left;
      @media (max-width: 768px) {
        font-size: 1.2rem;
        line-height: 1rem;
      }
      @media (max-width: 580px) {
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }
}
