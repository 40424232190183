@import url(https://fonts.googleapis.com/css2?family=Caudex&family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caudex&family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caudex&family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caudex&family=Questrial&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caudex&family=Questrial&display=swap);
/* Document
 * ========================================================================== *//**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}/* Sections
 * ========================================================================== *//**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content
 * ========================================================================== *//**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}/**
 * Add the correct display in IE.
 */main {
  display: block;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics
 * ========================================================================== *//**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */b,
strong {
  font-weight: bolder;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
 * Add the correct font size in all browsers.
 */small {
  font-size: 80%;
}/* Embedded content
 * ========================================================================== *//**
 * Hide the overflow in IE.
 */svg:not(:root) {
  overflow: hidden;
}/* Forms
 * ========================================================================== *//**
 * Remove the margin on controls in Safari.
 */button,
input,
select {
  margin: 0;
}/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}/**
 * Correct the inability to style buttons in iOS and Safari.
 */button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}/**
 * Correct the padding in Firefox.
 */fieldset {
  padding: 0.35em 0.75em 0.625em;
}/**
 * Show the overflow in Edge 18- and IE.
 */input {
  overflow: visible;
}/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}/**
 * Remove the inheritance of text transform in Firefox.
 */select {
  text-transform: none;
}/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */:-moz-focusring {
  outline: 1px dotted ButtonText;
}/**
 * Remove the additional :invalid styles in Firefox.
 */:-moz-ui-invalid {
  box-shadow: none;
}/* Interactive
 * ========================================================================== *//*
 * Add the correct display in Edge 18- and IE.
 */details {
  display: block;
}/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}dialog:not([open]) {
  display: none;
}/*
 * Add the correct display in all browsers.
 */summary {
  display: list-item;
}/* Scripting
 * ========================================================================== *//**
 * Add the correct display in IE.
 */template {
  display: none;
}/* User interaction
 * ========================================================================== */body{margin:0;font-family:"Questrial",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}*{box-sizing:border-box}
@-webkit-keyframes fadeIn{from{opacity:0}to{opacity:1}}@keyframes fadeIn{from{opacity:0}to{opacity:1}}.page{padding:0 20px}.page:nth-of-type(odd){background-color:#fafafa}@media(min-width: 768px){.page{padding:100px 0}}.home,.about,.psychodynamic,.services,.contact{opacity:.2}.home.in-view,.about.in-view,.psychodynamic.in-view,.services.in-view,.contact.in-view{-webkit-animation:fadeIn 100ms ease-in;animation:fadeIn 100ms ease-in;opacity:1}p,span{font-size:1.25rem}@media(max-width: 768px){p,span{font-size:1.1875rem}}
.main-nav{position:relative;max-width:100%;width:680px}.main-nav .main-nav-menu{display:flex;justify-content:space-between;list-style-type:none;font-family:"Questrial",sans-serif;font-size:1.125rem;padding:0}.main-nav .main-nav-menu li a{text-decoration:none;color:#000}.main-nav .main-nav-menu li a.active{padding-bottom:5px;border-bottom:1px solid #000}.main-nav .main-nav-menu li a:hover{opacity:.8}.main-nav~.hamburger{position:fixed;top:10px;left:10px;z-index:999;width:40px;height:40px}.main-nav~.hamburger:hover{cursor:pointer;opacity:.5}@media(min-width: 769px){.main-nav{flex-direction:row}.main-nav~.hamburger{display:none}}@media(max-width: 768px){.main-nav{display:none}.main-nav.open{display:block}.main-nav.open .main-nav-menu{display:flex;flex-direction:column;position:fixed;top:0;left:0;right:0;bottom:0;background-color:#e6e9e6;z-index:99;margin:0;justify-content:center;align-items:center;padding:0}.main-nav.open .main-nav-menu li{margin:10px 0}.main-nav.open .main-nav-menu li a{font-size:1.5rem}}.logo.small-screens{font-family:"Caudex",serif;font-size:24px;position:fixed;top:10px;left:58px;z-index:99}@media(min-width: 769px){.logo.small-screens{display:none}}.logo-sub.small-screens{font-size:16px;position:fixed;top:33px;left:60px;z-index:99}@media(min-width: 769px){.logo-sub.small-screens{display:none}}
.main-header{height:60px;display:flex;align-items:center;position:-webkit-sticky;position:sticky;top:0px;z-index:999999}.main-header .container{max-width:960px;width:100%;margin:0 auto;display:flex;align-items:center;justify-content:space-around;height:60px}@media(min-width: 768px){.main-header{background-color:#afafaf}}.logo{font-family:"Caudex",serif;font-size:20px;position:relative;padding-right:10px;display:block}@media(max-width: 768px){.logo{display:none}}.logo-sub{font-size:14px;position:relative;padding-right:10px;display:block}@media(max-width: 768px){.logo-sub{display:none}}
.page-wrapper{max-width:900px;margin:20px auto 0}@media(max-width: 810px){.page-wrapper{margin:0 auto;padding:20px 20px 0}}@media(max-width: 768px){.page-wrapper{padding:80px 20px 0}}
.page-content{display:grid;grid-template-columns:1fr 1fr;grid-column-gap:40px;-webkit-column-gap:40px;column-gap:40px;min-height:550px}.page-content img{max-width:100%;width:100%;object-fit:cover;height:100%;border-radius:10px}.page-content h2{border-bottom:1px solid #000;padding-bottom:15px;max-width:320px;margin-bottom:30px;margin-top:0}@media(max-width: 810px){.page-content{margin:0 auto 0;grid-template-columns:1fr}.page-content h2{margin-top:30px}.page-content img{max-height:150px;object-fit:cover}}@media(max-width: 580px){.page-content{min-height:auto}}.page-content .contact-details{display:block;padding-bottom:15px;color:#000;text-decoration:none}.page-content .contact-details:hover{text-decoration:underline}
.hero{position:relative;width:980px;max-width:100%;margin-left:0px;margin-top:0px;margin-bottom:0px;position:relative;background-color:#e6e9e6;display:flex;justify-content:space-between;flex-direction:column;padding:0px 20px 20px 20px}@media(min-width: 580px){.hero{margin-top:20px;flex-direction:row;height:403px;padding:0}}.hero img{max-width:100%;object-fit:contain;object-position:right}.hero .hero-content{display:flex;flex-direction:column;justify-content:center;padding:20px 0}@media(min-width: 580px){.hero .hero-content{margin-top:60px;flex-direction:row;height:403px;padding:20px}}@media(min-width: 768px){.hero .hero-content{padding:20px 20px 20px 60px}}.hero .text .main-text{font-size:3rem;font-family:"Caudex",serif;letter-spacing:.02em}@media(max-width: 768px){.hero .text .main-text{font-size:3rem}}@media(max-width: 580px){.hero .text .main-text{font-size:2rem}}.hero .text .sub-text{font-size:1.5rem;line-height:1.5rem}@media(max-width: 768px){.hero .text .sub-text{font-size:1.2rem;line-height:1rem}}@media(max-width: 580px){.hero .text .sub-text{font-size:1rem;line-height:1rem}}
.therapy-details{margin-top:40px}@media(max-width: 840px){.therapy-details{padding:0 20px}}@media(min-width: 768px){.therapy-details{margin-top:80px}}.therapy-details .heading{text-align:center;font-size:1.5rem}@media(min-width: 768px){.therapy-details .heading{font-size:2rem}}.therapy-details .therapy-details-content{display:grid;width:100%;max-width:800px;margin:40px auto;grid-template-columns:1fr 1fr}@media(max-width: 520px){.therapy-details .therapy-details-content{grid-template-rows:1fr auto;grid-template-columns:100%}}.therapy-details .therapy-details-content img{border-radius:10px;width:100%;max-width:100%}@media(max-width: 520px){.therapy-details .therapy-details-content img{grid-row-start:2;max-height:200px;object-fit:cover}}.therapy-details .therapy-details-content>div{display:flex;flex-direction:column;padding:0 30px}@media(max-width: 520px){.therapy-details .therapy-details-content>div{grid-row-start:1}}.therapy-details .therapy-details-content>div svg{width:100px;margin:0 auto;height:80px}

.layout{max-width:100%;width:980px;margin:0 auto;position:relative}.layout .app-header{height:60px;background-color:#fff}@media(max-width: 768px){.layout .app-header{padding-top:10px;position:fixed;top:0;left:0;right:0;z-index:999999}}.main-content-enter{opacity:0}.main-content-enter-active{opacity:1;transition:opacity 1000ms}.main-content-exit{opacity:1}.main-content-exit-active{opacity:0;transition:opacity 1000ms}
.footer{width:100%;height:80px;background-color:#e6e9e6;margin-top:80px;display:flex;justify-content:center;align-items:center}.footer .contact-details{display:block;padding-left:15px;color:#000;text-decoration:none}.footer .contact-details:hover{text-decoration:underline}
.service{display:grid;grid-gap:40px;gap:40px;grid-template-columns:1fr;margin-bottom:40px}@media(min-width: 581px){.service{grid-template-columns:1fr 1fr}}.service img{width:100%;max-height:250px;object-fit:cover;border-radius:10px}@media(min-width: 581px){.service img{max-width:100%;margin-bottom:0}}.service .service-heading{margin-top:0px;border-bottom:1px solid #000;padding-bottom:10px;max-width:320px}
.services{display:grid;grid-template-columns:1fr}@media(min-width: 581px){.services{grid-gap:40px;gap:40px}}
