.page-wrapper {
  max-width: 900px;
  margin: 20px auto 0;

  @media (max-width: 810px) {
    margin: 0 auto;
    padding: 20px 20px 0;
  }
  // @media (max-width: 810px) {
  //   padding: 60px 20px 0;
  // }

  @media (max-width: 768px) {
    padding: 80px 20px 0;
  }
}
