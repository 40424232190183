.footer {
  width: 100%;
  height: 80px;
  background-color: #e6e9e6;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  .contact-details {
    display: block;
    padding-left: 15px;
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
