.service {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;
  margin-bottom: 40px;

  @media (min-width: 581px) {
    grid-template-columns: 1fr 1fr;
  }

  img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    border-radius: 10px;

    @media (min-width: 581px) {
      max-width: 100%;
      margin-bottom: 0;
    }
  }

  .service-heading {
    margin-top: 0px;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    max-width: 320px;
  }
}
