@import '../styles/fonts';

.page-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  min-height: 550px;
  img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 10px;
  }

  h2 {
    border-bottom: 1px solid #000;
    padding-bottom: 15px;
    max-width: 320px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  @media (max-width: 810px) {
    h2 {
      margin-top: 30px;
    }
    margin: 0 auto 0;
    grid-template-columns: 1fr;
    img {
      max-height: 150px;
      object-fit: cover;
    }
  }
  @media (max-width: 580px) {
    min-height: auto;
  }
  .contact-details {
    display: block;
    padding-bottom: 15px;
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
