.layout {
  max-width: 100%;
  width: 980px;
  margin: 0 auto;
  position: relative;
  // @media (min-width: 581px) and (max-width: 768px) {
  //   padding-top: 10px;
  // }

  .app-header {
    height: 60px;
    background-color: #fff;
    @media (max-width: 768px) {
      padding-top: 10px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999999;
    }
  }
}

.main-content-enter {
  opacity: 0;
}
.main-content-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.main-content-exit {
  opacity: 1;
}
.main-content-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}
